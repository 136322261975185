import React from "react";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Gizlilik Politikası | davetlim.org</title>
        <meta name="description" content="davetlim.org'un Gizlilik Politikası sayfasında, kişisel bilgilerinizin nasıl toplandığını, kullanıldığını ve korunduğunu öğrenin. Gizliliğiniz bizim için önemlidir ve bu politika, verilerinizi nasıl güvende tuttuğumuzu açıklar." />
        <meta name="author" content="davetlim.org" />
        <meta name="robots" content="index, follow" />
        {/* Facebook Tags  */}
        <meta name="og:title" content="Gizlilik Politikası | davetlim.org" />
        <meta name="og:description" content="davetlim.org'un Gizlilik Politikası sayfasında, kişisel bilgilerinizin nasıl toplandığını, kullanıldığını ve korunduğunu öğrenin. Gizliliğiniz bizim için önemlidir ve bu politika, verilerinizi nasıl güvende tuttuğumuzu açıklar." />
        <meta name="og:url" content="https://davetlim.org/gizlilik-politikasi#top" />
        <meta name="og:site_name" content="davetlim.org" />
        <meta name="og:type" content="website" />
        <meta name="og:image" content="https://davetlim.org/static/media/Logo.1668ecc39d9ff250c6c6.png" />
        {/* Twitter tags  */}
        <meta name="twitter:title" content="Gizlilik Politikası | davetlim.org" />
        <meta name="twitter:description" content="davetlim.org'un Gizlilik Politikası sayfasında, kişisel bilgilerinizin nasıl toplandığını, kullanıldığını ve korunduğunu öğrenin. Gizliliğiniz bizim için önemlidir ve bu politika, verilerinizi nasıl güvende tuttuğumuzu açıklar." />
        <meta name="twitter:image" content="https://davetlim.org/static/media/Logo.1668ecc39d9ff250c6c6.png" />
        <meta name="twitter:image:alt" content="davetlim.org" />
        <meta name="twitter:site" content="https://davetlim.org/gizlilik-politikasi#top" />
        <meta name="twitter:creator" content="davetlim.org" />
      </Helmet>
      <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
        <div className="flex justify-between px-4 mx-auto max-w-screen-xl">
          <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
            <header className="mb-4 lg:mb-6 not-format">
              <h1 className="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">
                Gizlilik Politikası
              </h1>
            </header>
            <ol className="space-y-4 text-gray-500 list-decimal list-inside dark:text-gray-400">
              <li>
                Kişisel Bilgiler
                <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                  <p>
                    Davetlim.org web sitesi ("biz", "web sitemiz") üzerinden
                    erişebileceğiniz hizmetlerimizi kullanırken, kişisel
                    bilgilerinizi toplayabiliriz. Bu gizlilik politikası, web
                    sitemizde topladığımız, kullandığımız, paylaştığımız ve
                    sakladığımız kişisel bilgileri açıklar. Gizlilik
                    politikamızı kabul etmek, bu bilgilerin toplanmasını ve
                    kullanılmasını kabul ettiğiniz anlamına gelir.
                  </p>
                </ul>
              </li>
              <li>
                Topladığımız Bilgiler
                <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                  <p>Otomatik Toplanan Bilgiler</p>
                  <li className="list-none pl-4">
                    Davetlim.org web sitesini ziyaret ettiğinizde veya
                    hizmetlerimizi kullandığınızda, aşağıdaki bilgiler otomatik
                    olarak toplanır:
                    <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                      <li>İnternet Protokolü (IP) adresiniz</li>
                      <li>Tarayıcı ve cihaz özellikleriniz</li>
                      <li>İşletim sisteminiz</li>
                      <li>Dil tercihleriniz</li>
                      <li>Yönlendiren URL'ler</li>
                      <li>Cihaz adı</li>
                      <li>Ülke</li>
                      <li>Konum</li>
                    </ul>
                  </li>
                  <li className="list-none pl-4 pt-4">
                    Bu bilgiler, özel kimliğinizi (adınız veya iletişim
                    bilgileriniz gibi) ifşa etmez. Ancak, cihaz ve kullanım
                    bilgilerinizi içerebilir. Bu bilgiler öncelikle
                    Hizmetlerimizin güvenliğini ve işleyişini sürdürmek, analiz
                    ve raporlama amaçlarımız için gereklidir.
                  </li>
                  <li className="list-none pl-4">
                    Topladığımız Diğer Bilgiler
                    <ul className="pl-5 mt-2 space-y-1 list-inside">
                      <li>Adınız ve Soyadınız</li>
                      <li>E-posta adresiniz</li>
                      <li>Telefon numaranız</li>
                      <li>Kullanıcı adı ve şifre</li>
                      <li>Coğrafi konum bilgileri (isteğe bağlı olarak)</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                Kişisel Bilgilerin Kullanım Amaçları
                <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                  <li className="list-none pl-4">
                    Topladığımız kişisel bilgiler aşağıdaki amaçlar için
                    kullanılabilir:
                    <ul className="pl-5 mt-2 space-y-1 list-inside">
                      <li>Üyelik işlemleri ve hesap yönetimi</li>
                      <li>
                        Etkinlik davetiyelerinin oluşturulması ve gönderilmesi
                      </li>
                      <li>
                        Hizmetlerimizin iyileştirilmesi ve kişiselleştirilmesi
                      </li>
                      <li>
                        İlgili mevzuata uygun şekilde kişisel verilerin
                        işlenmesi
                      </li>
                      <li>
                        İletişim kurabilmemiz için gerekli analizlerin yapılması
                      </li>
                      <li>
                        Kampanyalar, anketler ve özel etkinlikler gibi ilgili
                        ürünlerin tanıtımı
                      </li>
                      <li>Kullanıcıların sorularının cevaplanması</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                Kişisel Bilgilerin Paylaşımı
                <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                  <p>
                    Kişisel bilgileriniz, yasal gereklilikleri yerine getirmek,
                    hizmetlerimizi sunmak, güvenlik önlemleri almak veya resmi
                    mercilerin taleplerini yerine getirmek amacıyla üçüncü
                    kişilerle paylaşılabilir.
                  </p>
                </ul>
              </li>
              <li>
                Çerezler (Cookies)
                <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                  <p>
                    Web sitemiz, çerezleri kullanarak kullanıcı deneyimini
                    iyileştirmeyi amaçlar. Çerezler hakkında daha fazla bilgi
                    almak ve bunları nasıl kontrol edebileceğinizi öğrenmek için
                    lütfen çerez politikamızı inceleyin.
                  </p>
                </ul>
              </li>
              <li>
                Değişiklikler
                <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                  <p>
                    Bu gizlilik politikası zaman zaman güncellenebilir.
                    Değişiklikleri önceden bildirmeksizin yapabiliriz.
                    Güncellemeler ile ilgili bilgilendirme yapmak davetlim.org
                    sorumluluğundadır.
                  </p>
                </ul>
              </li>
              <li>
                İletişim Bilgileri
                <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                  <p>
                    Gizlilik politikamızla ilgili herhangi bir sorunuz veya
                    talebiniz varsa, lütfen aşağıdaki iletişim bilgilerini
                    kullanarak bizimle iletişime geçin:
                  </p>
                  <p>
                    E-posta: hello@infinicode.co.uk <br />
                    Adres: Zühtüpaşa Mah. Şefik Bey Sk. Archerson Köşkü No:3
                    34724 Kadıköy/İstanbul
                  </p>
                </ul>
              </li>
              <li>
                Kullanıcı Onayı
                <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                  <p>
                    Web sitemizi kullanarak ve hizmetlerimizi kullanarak, bu
                    gizlilik politikasını kabul etmiş sayılırsınız. Bu politika,
                    son güncelleme tarihinden itibaren yürürlüktedir.
                  </p>
                </ul>
              </li>
              <li>
                Kişisel Verileri İşlenen Kişi Olarak Haklarınız KVKK madde 11
                uyarınca herkes, veri sorumlusuna başvurarak aşağıdaki haklarını
                kullanabilir:
                <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                  <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                    <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
                    <li>
                      Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
                    </li>
                    <li>
                      Kişisel verilerin işlenme amacını ve bunların amacına
                      uygun kullanılıp kullanılmadığını öğrenme,
                    </li>
                    <li>
                      Yurt içinde veya yurt dışında kişisel verilerin
                      aktarıldığı üçüncü kişileri bilme,
                    </li>
                    <li>
                      Kişisel verilerin eksik veya yanlış işlenmiş olması
                      hâlinde bunların düzeltilmesini isteme,
                    </li>
                    <li>
                      Kişisel verilerin silinmesini veya yok edilmesini isteme,
                    </li>
                    <li>
                      (e) ve (f) bentleri uyarınca yapılan işlemlerin, kişisel
                      verilerin aktarıldığı üçüncü kişilere bildirilmesini
                      isteme,
                    </li>
                    <li>
                      İşlenen verilerin münhasıran otomatik sistemler
                      vasıtasıyla analiz edilmesi suretiyle kişinin kendisi
                      aleyhine bir sonucun ortaya çıkmasına itiraz etme,
                    </li>
                    <li>
                      Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                      zarara uğraması hâlinde zararın giderilmesini talep etme,
                      haklarına sahiptir.
                    </li>
                  </ul>
                </ul>
              </li>

              <p>
                Yukarıda sayılan haklarınızı kullanmak üzere
                <b>contact@keybirdsoft.com</b> üzerinden bizimle iletişime
                geçebilirsiniz.
              </p>
            </ol>
          </article>
        </div>
      </main>
    </>
  );
}

export default PrivacyPolicy;
