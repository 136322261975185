import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import MockupGIF from "../assets/img/mockup-min.gif";
import LandingPage from "../assets/img/Landing Page Görsel.png";
import DontCall from "../assets/img/Dont Call.png";
import Observe from "../assets/img/Observe.png";
import WhatsApp from "../assets/img/WhatsApp Logo.png";
import { Helmet } from "react-helmet";

function Home() {
  const [price, setPrice] = useState(10);

  let netPrice = 0;
  const creditAmount = price;
  netPrice = creditAmount * 30;

  const handlePriceChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setPrice(newValue);
  };
  return (
    <>
      <Helmet>
        <title>WhatsApp ile LCV | davetlim.org</title>
        <meta
          name="description"
          content="WhatsApp Üzerinden Online Davetiye ve Gerçek Zamanlı LCV Takip Sistemi"
        />
        <meta name="author" content="davetlim.org" />
        <meta name="robots" content="index, follow" />
        {/* Facebook Tags  */}
        <meta name="og:title" content="WhatsApp ile LCV | davetlim.org" />
        <meta
          name="og:description"
          content="WhatsApp Üzerinden Online Davetiye ve Gerçek Zamanlı LCV Takip Sistemi"
        />
        <meta name="og:url" content="https://davetlim.org/" />
        <meta name="og:site_name" content="davetlim.org" />
        <meta name="og:type" content="website" />
        <meta
          name="og:image"
          content="https://davetlim.org/static/media/Logo.1668ecc39d9ff250c6c6.png"
        />
        {/* Twitter tags  */}
        <meta name="twitter:title" content="WhatsApp ile LCV | davetlim.org" />
        <meta
          name="twitter:description"
          content="WhatsApp Üzerinden Online Davetiye ve Gerçek Zamanlı LCV Takip Sistemi"
        />
        <meta
          name="twitter:image"
          content="https://davetlim.org/static/media/Logo.1668ecc39d9ff250c6c6.png"
        />
        <meta name="twitter:image:alt" content="davetlim.org" />
        <meta name="twitter:site" content="https://davetlim.org/" />
        <meta name="twitter:creator" content="davetlim.org" />
      </Helmet>
      {/* <!-- Hero --> */}
      <section className="bg-white ">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl ">
              Yeni Nesil WhatsApp Üzerinden LCV Sistemi
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl ">
              WhatsApp Üzerinden Online Davetiye ve Anlık LCV Takip Sistemi
            </p>
            <a
              href="/iletisim"
              className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 "
            >
              Fiyat Teklifi Al
            </a>
          </div>
          <div className="lg:mt-0 mt-14 lg:col-span-5 lg:flex">
            <div className="relative mx-auto ">
              <div className="flex justify-center overflow-hidden  bg-white ">
                <img src={MockupGIF} className="w-[272px] " alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature list with */}
      <section className="bg-white ">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center sm:py-8 lg:px-6">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
            Yeni Nesil LCV Sistemi ile Davetlilerin Yanıtlarını Kolayca Takip
            Edin!
          </h2>
          <p className="text-gray-500 sm:text-xl ">
            Eski iletişim yöntemlerini (arama, SMS ve e-posta gibi) minimuma
            indirerek Tüm LCV sürecinizi pratik bir şekilde WhatsApp üzerinden
            tamamlayın!
          </p>
          <div className="mt-8 lg:mt-12 space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            <div>
              <img
                className="mx-auto mb-4 w-12 h-12"
                src={WhatsApp}
                alt="feature image"
              />
              <h3 className="mb-2 text-xl font-bold ">
                Yanıt Oranımız %90'ın Üzerinde!
              </h3>
              <p className="mb-4 text-gray-500 ">
                Davetlilerin büyük çoğunluğu sistemimiz üzerinden otomatik
                olarak yanıt vermektedir. WhatsApp üzerinden ulaşılamayan
                misafirlerimiz ise çağrı merkezimiz tarafından aranarak LCV
                süreçleri tamamlanır.
              </p>
            </div>
            <div>
              <img
                className="mx-auto mb-4 w-12 h-12"
                src={DontCall}
                alt="feature image"
              />
              <h3 className="mb-2 text-xl font-bold ">
                Rahatsız Etmeden Yanıt Alma
              </h3>
              <p className="mb-4 text-gray-500 ">
                Gereksiz arama ve mesaj trafiğine son! Davetlileriniz, uygun
                olduklarında davetiyenizi yanıtlayabilirler. Ardından,
                planlarında bir değişiklik olursa yanıtlarını aynı mesaj
                üzerinden güncelleyebilirler.
              </p>
            </div>
            <div>
              <img
                className="mx-auto mb-4 w-12 h-12"
                src={Observe}
                alt="feature image"
              />
              <h3 className="mb-2 text-xl font-bold ">Anlık Yanıtları İzle</h3>
              <p className="mb-4 text-gray-500 ">
                Tüm yanıtları anında, size özel açılan panel üzerinden takip
                edebilirsiniz.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Content --> */}
      <section className="bg-white ">
        <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div className="font-light text-gray-500 sm:text-lg ">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
              Dijital Davetiye ve LCV Ne Demek?
            </h2>
            <p className="mb-4">
              Davetlim.org ile şık online davetiyelerinizi tek bir tıkla
              Whatsapp üzerinden tüm misafirlerinizle paylaşabilirsiniz. Artık
              tek tek link gönderme zahmetine gerek yok. Sevdikleriniz yine
              Whatsapp üzerinden tek bir tıkla katılım durumlarını
              iletebilirler. Online davetiye ile davetlerinizi kolayca yönetin
              ve özel gününüzü daha özel kılın.
            </p>
          </div>
          <div className="mt-4 font-light text-gray-500 sm:text-lg lg:mt-0 ">
            <p className="mb-4">
              LCV nedir?, "Lütfen Cevap Veriniz" ifadesinin kısaltmasıdır ve
              özellikle davetiye ve organizasyonlarda kullanılan önemli bir
              terimdir. Bu terim, Fransızca "Répondez s'il vous plaît"
              ifadesinden türetilmiş olan RSVP'nin Türkçe karşılığıdır. Bir
              davetiyede veya etkinlik davetinde LCV ifadesi görüyorsanız, bu,
              davetiye sahibinin katılım veya cevap almayı amaçladığını
              gösterir. Davetlilerden, davetiye üzerinden yanıt vermeleri veya
              katılıp katılmama durumlarını iletmeleri beklenir. Bu sayede
              organizasyonun daha iyi bir şekilde planlanması ve düzenlenmesi
              mümkün olur.
            </p>
          </div>
        </div>

        {/* How does it work section */}
        <div
          className="py-8 px-4 mx-auto max-w-screen-xl sm:py-8 lg:px-6"
          id="nasil-calisir"
        >
          <div className="max-w-screen-md mb-8 lg:mb-16">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
              Nasıl Çalışıyoruz?
            </h2>
            <p className="font-light text-gray-500  sm:text-xl">
              davetlim.org, Yeni Nesil WhatsApp LCV altyapısıyla,
              davetlilerinizin katılım durumunu öğrenmek ve takip etmek için hem
              kolay hem de çok pratik bir yöntem sunar!
            </p>
          </div>
          <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-8 xl:gap-12 md:space-y-0">
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 bg-orange-100 rounded  lg:h-16 lg:w-16">
                <svg
                  className="w-5 h-5 text-orange-600  lg:w-8 lg:h-8"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12.356 5.435 1.938 16.384a.5.5 0 0 0 .018.707l2.9 2.757a.5.5 0 0 0 .706-.018L15.978 8.882l-3.622-3.447Zm7.681-.819a.5.5 0 0 0-.018-.706l-2.9-2.757a.5.5 0 0 0-.707.017l-2.68 2.817 3.622 3.446 2.683-2.817Zm-2.89 12.233-1 .025-.024-1a1 1 0 1 0-2 .05l.025 1-1 .024a1 1 0 0 0 .05 2l1-.025.025 1a1 1 0 1 0 2-.05l-.025-1 1-.024a1 1 0 1 0-.05-2h-.001ZM2.953 9.2l.025 1a1 1 0 1 0 2-.05l-.025-1 1-.025a1 1 0 1 0-.05-2l-1 .025-.025-1a1 1 0 0 0-2 .049l.025 1-1 .025a1 1 0 0 0 .05 2l1-.024Zm15.07 2.626-2 .05.05 1.999 2-.05-.05-1.999ZM11.752.978l-2 .05.05 2 2-.05-.05-2Zm-2.95 2.075-2 .05.05 1.999 2-.05-.05-2ZM5.753 1.127l-1.999.05.05 2 1.999-.05-.05-2Zm15.194 7.625-2 .05.05 2 2-.05-.05-2Zm.125 4.998-2 .05.05 2 2-.05-.05-2Z" />
                </svg>
              </div>
              <h3 className="mb-2 text-xl font-bold  text-gray-900">
                1. LCV Talep Formunu Doldurun
              </h3>
              <ul role="list" className="my-6 lg:mb-0 space-y-4">
                <li className="flex space-x-2.5">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-orange-600 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="leading-tight text-gray-500 ">
                    <a
                      href="/iletisim"
                      className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:ring-orange-300 "
                    >
                      Talep Formu
                    </a>
                  </span>
                </li>
                <li className="flex space-x-2.5">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-orange-600 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="leading-tight text-gray-500 ">
                    Müşteri temsilcimiz, fiyat bilgisi ve etkinlik detaylarınızı
                    girebileceğiniz form ile birlikte 1 saat içinde sizinle
                    iletişime geçecektir.
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 bg-purple-100 rounded lg:h-16 lg:w-16">
                <svg
                  className="w-5 h-5 text-purple-600 lg:w-8 lg:h-8"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Zm11-3h-2V5a1 1 0 0 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 0 0 2 0V9h2a1 1 0 1 0 0-2Z" />
                </svg>
              </div>
              <h3 className="mb-2 text-xl font-bold  text-gray-900">
                2. Etkinlik Detaylarınızı Girin
              </h3>
              <ul role="list" className="my-6 lg:mb-0 space-y-4">
                <li className="flex space-x-2.5">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-purple-600 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="leading-tight text-gray-500 ">
                    Müşteri temsilcimiz tarafından paylaşılan form linki
                    üzerinden, etkinlik bilgilerinizi kolayca doldurabilirsiniz.
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 bg-teal-100 rounded lg:h-16 lg:w-16">
                <svg
                  className="w-5 h-5 text-teal-600  lg:w-8 lg:h-8"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2.057 6.9a8.718 8.718 0 0 1 6.41-3.62v-1.2A2.064 2.064 0 0 1 9.626.2a1.979 1.979 0 0 1 2.1.23l5.481 4.308a2.107 2.107 0 0 1 0 3.3l-5.479 4.308a1.977 1.977 0 0 1-2.1.228 2.063 2.063 0 0 1-1.158-1.876v-.942c-5.32 1.284-6.2 5.25-6.238 5.44a1 1 0 0 1-.921.807h-.06a1 1 0 0 1-.953-.7A10.24 10.24 0 0 1 2.057 6.9Z" />
                </svg>
              </div>
              <h3 className="mb-2 text-xl font-bold  text-gray-900">
                Herşey Hazır!
              </h3>
              <ul role="list" className="my-6 lg:mb-0 space-y-4">
                <li className="flex space-x-2.5">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-teal-500 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="leading-tight text-gray-500 ">
                    İstediğiniz tarih ve saatte, tüm katılımcılara interaktif
                    davetiyeleriniz gönderilmeye hazır olacak. (Gönderim
                    sayısına göre, gönderilerin tamamlanması 1 dakika ile 1 saat
                    arasında değişir.)
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/*  */}
        <section className="bg-white ">
          <div className="gap-8 items-start py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 xl:gap-16 sm:py-8 lg:px-6 ">
            <img
              className="mb-4 w-full lg:mb-0 rounded-lg"
              src={LandingPage}
              alt="feature image"
            />
            <div className="text-gray-500  sm:text-lg">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
                Sistem nasıl çalışıyor ve avantajları nelerdir?
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Davetiye görselinizi, etkinlik tarihini, mesaj içeriğini ve
                diğer gerekli bilgileri girdikten sonra, hazırladığınız Excel
                katılımcı listesine toplu bir şekilde davetiyeleriniz
                gönderilir. <br /> Bundan sonra, size sadece katılımcıların
                durumunu anlık olarak takip etmek kalır. Hangi katılımcıların
                yanıt verdiğini, hangilerinin henüz cevaplamadığını kolayca
                görebilirsiniz.
              </p>
              <div className="py-8 mb-6 border-t border-b border-gray-200 ">
                <div className="flex">
                  <div className="flex justify-center items-center mr-4 w-8 h-8 text-4xl">
                    -
                  </div>
                  <div>
                    <p className="mb-2 font-light text-gray-500 ">
                      Tüm süreç, WhatsApp üzerinden butonlar aracılığıyla
                      gerçekleşir. Bu nedenle, kullanımı son derece basittir.
                    </p>
                  </div>
                </div>
                <div className="flex xl:pt-8 pt-4">
                  <div className="flex justify-center items-center mr-4 w-8 h-8 text-4xl">
                    -
                  </div>
                  <div>
                    <p className="mb-2 font-light text-gray-500 ">
                      WhatsApp üzerinden iletişim, katılımcıların %90'ından
                      fazlasının hızlı bir şekilde yanıt vermesini sağlar. Bu,
                      etkinlik planlamanızı daha verimli hale getirir.
                    </p>
                  </div>
                </div>
                <div className="flex xl:pt-8 pt-4">
                  <div className="flex justify-center items-center mr-4 w-8 h-8 text-4xl">
                    -
                  </div>
                  <div>
                    <p className="mb-2 font-light text-gray-500 ">
                      Katılımcılar davetiyeye istedikleri zaman yanıt verebilir
                      ve gerektiğinde değişiklik yapabilirler. Bu, planlama
                      sürecinizi daha esnek ve kullanıcı dostu hale getirir.
                    </p>
                  </div>
                </div>
                <div className="flex xl:pt-8 pt-4">
                  <div className="flex justify-center items-center mr-4 w-8 h-8 text-4xl">
                    -
                  </div>
                  <div>
                    <p className="mb-2 font-light text-gray-500 ">
                      WhatsApp üzerinden ulaşılamayan katılımcılar, çağrı
                      merkezimiz tarafından aranarak LCV işlemleri tamamlanır.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Row --> */}
        <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <img
            className="hidden mb-4 w-full lg:mb-0 lg:flex rounded-lg"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/features/feature-office-1.png"
            alt="office feature image"
          />
          <div className="text-gray-500 sm:text-lg ">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
              Neden davetlim.org?
            </h2>
            <p className="mb-8 font-light lg:text-xl">
              Her şey hazır, Tek Tıkla Gönder!
            </p>
            {/* <!-- List --> */}

            <p className="mb-8 font-light lg:text-xl">
              Davetlim.org, etkinlikleriniz için mükemmel dijital davetiyeleri
              oluşturmanıza ve online davetiye göndermeyi Whatsapp üzerinden
              kolayca gerçekleştirmenize yardımcı olan, önde gelen bir
              platformdur. Meta INC Cloud altyapısını kullanarak size destek
              oluyoruz. Şimdi her birine ayrı ayrı link göndermekle uğraşmadan,
              hızlıca davetiyelerinizi oluşturabilir ve tek bir tıklama ile tüm
              misafirlerinize online davetiyenizi gönderebilirsiniz. Ayrıca,
              davetlileriniz katılımlarını hızlı ve spam gibi görünen linklere
              tıklamadan Whatsapp üzerinden bildirebilirler. Misafirlerinizin
              anlık katılım durumunu takip edebileceğiniz Canlı Konuk Katılımı
              Takibi (LCV) özelliği de mevcuttur. Davetlim.org, etkinliklerinizi
              daha iyi planlamanızda size yardımcı olarak stresten ve zaman
              kaybından kurtarır.
            </p>
          </div>
        </div>
      </section>

      {/* <!-- FAQ --> */}
      <section className="bg-white " id="SSS">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-8 lg:px-6">
          <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 ">
            Sıkça Sorulan Sorular
          </h2>
          <div className="grid pt-8 text-left border-t border-gray-200 md:gap-16  md:grid-cols-2">
            <div>
              <div className="mb-10">
                <h3 className="flex items-start mb-4 text-lg font-medium text-gray-900 ">
                  <svg
                    className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500  mt-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Farklı Dillerde Davetiye ve Yurtdışı Telefon Numaraları
                </h3>
                <p className="text-gray-500 ">
                  Farklı Dillerde Davetiye ve Yurtdışı Telefon Numaraları Eğer
                  farklı dillerde davetiyeler göndermek istiyorsanız veya
                  yurtdışı telefon numaralarına da davetiye göndermek
                  istiyorsanız endişelenmeyin! Tüm dillerde destek sunuyoruz ve
                  gönderilecek numaranın WhatsApp kullanıyor olması yeterlidir.
                  (Kullanmayan kaldımı ki 😀)
                </p>
              </div>
              <div className="mb-10">
                <h3 className="flex items-start mb-4 text-lg font-medium text-gray-900 ">
                  <svg
                    className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500  mt-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Kişi Sayısı Nasıl Hesaplanır?
                </h3>
                <p className="text-gray-500 ">
                  davetlim.org'da kişi sayısını hesaplamak çok basit! Katılımcı
                  listesine eklenen her bir telefon numarası 1 kişi olarak
                  sayılır.
                </p>
              </div>
              <div className="mb-10">
                <h3 className="flex items-start mb-4 text-lg font-medium text-gray-900 ">
                  <svg
                    className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500  mt-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Birden Fazla Parti Gönderimi
                </h3>
                <p className="text-gray-500 ">
                  davetlim.org, davetiyeleri yeniden göndermenize veya yeni
                  kişilere davetiye göndermenize her zaman olanak tanır.
                </p>
              </div>
              <div className="mb-10">
                <h3 className="flex items-start mb-4 text-lg font-medium text-gray-900 ">
                  <svg
                    className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500  mt-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  LCV Takip Sistemi
                </h3>
                <p className="text-gray-500 ">
                  Evet! davetlim.org'un kapsamlı LCV takip ekranı planlamanızı
                  kolaylaştırır.
                </p>
              </div>
            </div>
            <div>
              <div className="mb-10">
                <h3 className="flex items-start mb-4 text-lg font-medium text-gray-900 ">
                  <svg
                    className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500  mt-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Yardım Merkezi
                </h3>
                <p className="text-gray-500 ">
                  Herhangi bir yardıma ihtiyacınız olduğunda endişelenmeyin!
                  Yardım Merkezimiz, size rehberlik etmeye ve sorularınızı
                  yanıtlamaya hazırdır. Ek sorularınız için, şirket içi müşteri
                  destek ekibimiz Pazartesi'den Cuma'ya 10:00 - 19:00 saatleri
                  arasında destek@davetlim.org adresinden size yardımcı
                  olacaktır.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
