import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import DistanceSellingAgreement from "./pages/DistanceSellingAgreement";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsofUse from "./pages/TermsofUse";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { Toaster } from "react-hot-toast";
import AboutUs from "./pages/AboutUs";

function App() {
  return (
    <Router>
      <div>
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{
            error: {
              duration: 3000,
              style: {
                background: "#fdf2f2",
                color: "#9b1c1c",
              },
            },
            success: {
              duration: 3000,
              style: {
                background: "#f3faf7",
                color: "#03543f",
              },
            },
          }}
        />
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/iletisim" element={<Contact />} />
          <Route
            path="/mesafeli-satis-sozlesmesi"
            element={<DistanceSellingAgreement />}
          />
          <Route path="/gizlilik-politikasi" element={<PrivacyPolicy />} />
          <Route path="/hakkimizda" element={<AboutUs />} />
          <Route path="/kullanim-kosullari" element={<TermsofUse />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
