import React from "react";
import { Helmet } from "react-helmet";

function AboutUs() {
  return (
    <>
      <Helmet>
        <title>Hakkımızda | davetlim.org</title>
        <meta name="description" content="davetlim.org, davetliler için özel çözümler sunar. Bizim hakkımızda daha fazla bilgi edinin ve etkinliğinizi nasıl daha iyi hale getirebileceğimizi keşfedin." />
        <meta name="author" content="davetlim.org" />
        <meta name="robots" content="index, follow" />
        {/* Facebook Tags  */}
        <meta name="og:title" content="Hakkımızda | davetlim.org" />
        <meta name="og:description" content="davetlim.org, davetliler için özel çözümler sunar. Bizim hakkımızda daha fazla bilgi edinin ve etkinliğinizi nasıl daha iyi hale getirebileceğimizi keşfedin." />
        <meta name="og:url" content="https://davetlim.org/hakkimizda#top" />
        <meta name="og:site_name" content="davetlim.org" />
        <meta name="og:type" content="website" />
        <meta name="og:image" content="https://davetlim.org/static/media/Logo.1668ecc39d9ff250c6c6.png" />
        {/* Twitter tags  */}
        <meta name="twitter:title" content="Hakkımızda | davetlim.org" />
        <meta name="twitter:description" content="davetlim.org, davetliler için özel çözümler sunar. Bizim hakkımızda daha fazla bilgi edinin ve etkinliğinizi nasıl daha iyi hale getirebileceğimizi keşfedin." />
        <meta name="twitter:image" content="https://davetlim.org/static/media/Logo.1668ecc39d9ff250c6c6.png" />
        <meta name="twitter:image:alt" content="davetlim.org" />
        <meta name="twitter:site" content="https://davetlim.org/hakkimizda#top" />
        <meta name="twitter:creator" content="davetlim.org" />
      </Helmet>
      <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
        <div className="flex justify-between px-4 mx-auto max-w-screen-xl">
          <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
            <header className="mb-4 lg:mb-6 not-format">
              <h1 className="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">
                Hakkımızda
              </h1>
            </header>
            <div className="space-y-4 text-gray-500 list-decimal list-inside dark:text-gray-400">
              <p>
                Davetlim.org, Ekim 2023 itibariyle hayata geçirilmiş olan bir
                InfiniCode projesidir. Etkinlikleriniz için mükemmel dijital
                düğün davetiyeleri oluşturmanıza ve online davetiye göndermeyi
                Whatsapp üzerinden kolayca gerçekleştirmenize yardımcı olan önde
                gelen bir platformdur. Meta INC Cloud altyapısını kullanarak
                size destek oluyoruz. Artık her birine ayrı ayrı link
                göndermekle uğraşmadan, hızlıca davetiyelerinizi oluşturabilir
                ve tek bir tıklama ile tüm misafirlerinize online davetiyenizi
                gönderebilirsiniz. Ayrıca, sevdikleriniz katılımlarını hızlı ve
                spam gibi görünen linklere tıklamadan Whatsapp üzerinden
                bildirebilirler. Misafirlerinizin anlık katılım durumunu takip
                edebileceğiniz Canlı Konuk Katılımı Takibi (LCV) özelliği de
                mevcuttur. Davetlim.org, etkinliklerinizi daha iyi planlamanızda
                size yardımcı olur, böylece stresten ve zaman kaybından
                kurtarır. Ücretsiz olarak test ederek etkinliklerinizi daha
                kolay, daha hızlı ve daha keyifli hale getirmeye
                başlayabilirsiniz!
              </p>
            </div>
          </article>
        </div>
      </main>
    </>
  );
}

export default AboutUs;
