import React from "react";
import { Helmet } from "react-helmet";

function DistanceSellingAgreement() {
  return (
    <>
      <Helmet>
        <title>Mesafeli Satış Sözleşmesi | davetlim.org</title>
        <meta name="description" content="davetlim.org'un Mesafeli Satış Sözleşmesi sayfasında, satın alma şartlarımızı, iade politikamızı ve diğer önemli satış bilgilerimizi öğrenin. Bizimle güvenli ve bilinçli bir şekilde alışveriş yapın." />
        <meta name="author" content="davetlim.org" />
        <meta name="robots" content="index, follow" />
        {/* Facebook Tags  */}
        <meta name="og:title" content="Mesafeli Satış Sözleşmesi | davetlim.org" />
        <meta name="og:description" content="davetlim.org'un Mesafeli Satış Sözleşmesi sayfasında, satın alma şartlarımızı, iade politikamızı ve diğer önemli satış bilgilerimizi öğrenin. Bizimle güvenli ve bilinçli bir şekilde alışveriş yapın." />
        <meta name="og:url" content="https://davetlim.org/mesafeli-satis-sozlesmesi#top" />
        <meta name="og:site_name" content="davetlim.org" />
        <meta name="og:type" content="website" />
        <meta name="og:image" content="https://davetlim.org/static/media/Logo.1668ecc39d9ff250c6c6.png" />
        {/* Twitter tags  */}
        <meta name="twitter:title" content="Mesafeli Satış Sözleşmesi | davetlim.org" />
        <meta name="twitter:description" content="davetlim.org'un Mesafeli Satış Sözleşmesi sayfasında, satın alma şartlarımızı, iade politikamızı ve diğer önemli satış bilgilerimizi öğrenin. Bizimle güvenli ve bilinçli bir şekilde alışveriş yapın." />
        <meta name="twitter:image" content="https://davetlim.org/static/media/Logo.1668ecc39d9ff250c6c6.png" />
        <meta name="twitter:image:alt" content="davetlim.org" />
        <meta name="twitter:site" content="https://davetlim.org/mesafeli-satis-sozlesmesi#top" />
        <meta name="twitter:creator" content="davetlim.org" />
      </Helmet>
      <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
        <div className="flex justify-between px-4 mx-auto max-w-screen-xl">
          <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
            <header className="mb-4 lg:mb-6 not-format">
              <h1 className="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">
                Mesafeli Satış Sözleşmesi
              </h1>
            </header>
            <ol className="space-y-4 text-gray-500 list-inside dark:text-gray-400">
              <li>
                <h2>MADDE 1- TARAFLAR</h2>
                <h3>1.1- SATICI</h3>
                <ul className="pl-5 mt-2 space-y-1 list-inside">
                  <li>
                    Adı Soyadı / Ünvanı: KEYBİRDSOFT YAZILIM VE BİLİŞİM
                    TEKNOLOJİLERİ ANONİM ŞİRKETİ
                  </li>
                  <li>
                    Adresi: Muratreis Mahallesi Selamet Sokak No:9 / Üsküdar /
                    İstanbul
                  </li>
                  <li>
                    E-posta:
                    <a href="mailto:contact@keybirdsoft.com">
                      contact@keybirdsoft.com
                    </a>
                  </li>
                  <li>Vergi Dairesi: Üsküdar</li>
                  <li>Vergi Numarası: 5481274533</li>
                </ul>
                <h3>1.2- ALICI</h3>
                <ul className="pl-5 mt-2 space-y-1 list-inside">
                  <li>Sipariş Detayları</li>
                  <ul className="pl-5 mt-2 space-y-1 list-inside">
                    <li>Sipariş No: ****</li>
                    <li>Sipariş Detayları: ****</li>
                    <li>Ödeme Metodu: *****</li>
                    <li>Teslimat Metodu: *****</li>
                    <li>E-Posta: ****</li>
                    <li>Telefon: ****</li>
                    <li>IP Adresiniz: ****</li>
                  </ul>
                </ul>
              </li>
              <li>
                <h2>MADDE 2- SÖZLEŞMENİN KONUSU:</h2>
                <p>
                  İşbu Mesafeli Satış Sözleşmesi’nin (Bundan sonra Sözleşme
                  olarak anılacaktır) konusu, aşağıdaki nitelikleri ve satış
                  fiyatı belirlenen dijital ürün ile ilgili olarak 4077 sayılı
                  Tüketicinin Korunması Hakkındaki Kanun ve Mesafeli
                  Sözleşmelere Dair Yönetmelik hükümleri gereğince tarafların
                  hak ve yükümlülüklerinin tespitidir.
                </p>
              </li>
              <li>
                <h2>MADDE 3- ÜRÜN BİLGİLERİ:</h2>
                <p>
                  İşbu Sözleşme konusu dijital ürüne; ürünün satış bedeline,
                  teslim ve ödeme şekillerine ait bilgiler aşağıdaki gibidir:
                </p>

                <ul className="pl-5 mt-2 space-y-1 list-inside">
                  <li>Ürün: *****</li>
                  <li>Ürün Kodu: *****</li>
                  <li>Adet: *****</li>
                  <li>Birim Fiyatı: ****TL</li>
                  <li>Tutarı: ************TL</li>
                </ul>
              </li>
              <li>
                <h2>MADDE 4 – GENEL HÜKÜMLER</h2>

                <ul className="pl-5 mt-2 space-y-1 list-inside">
                  <li>
                    4.1 Alıcı, Madde 3’de belirtilen işbu Sözleşme konusu
                    dijital ürünün temel nitelikleri, satış fiyatı ve ödeme
                    şekli ile ilgili ürün bilgilerini okuduğunu ve işbu Sözleşme
                    akdedilmeden önce gerekli onayı verdiğini kabul ve beyan
                    eder.
                  </li>
                  <li>
                    4.2 Satıcı, Sözleşme konusu dijital ürünün, sözleşme
                    şartlarına uygun olarak ve belirtilen özelliklere sahip
                    olarak teslim edilmesinden sorumludur.
                  </li>
                  <li>
                    4.3 Alıcı, işbu Sözleşme öncesinde dijital ürüne ilişkin ön
                    bilgilendirme formunu incelediğini ve onayladığını kabul,
                    beyan ve taahhüt eder.
                  </li>
                  <li>
                    4.4 Taraflar işbu Sözleşme şartlarının yanı sıra 4077 sayılı
                    Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli
                    Sözleşmelere Dair Yönetmelik hükümlerini kabul ettiklerini
                    ve bu hükümlere uygun hareket edeceklerini kabul ve beyan
                    ederler.
                  </li>
                  <li>
                    4.5 İşbu Sözleşme’nin tüm maddeleri Satıcı ve Alıcı
                    tarafından okunmuş ve kabul edilmiş olup, işbu Sözleşme
                    Alıcı tarafından elektronik olarak onaylandığı tarihte
                    yürürlüğe girer.
                  </li>
                </ul>
              </li>
              <li>
                <h2>MADDE 5- CAYMA HAKKI:</h2>
                <ul className="pl-5 mt-2 space-y-1 list-inside">
                  <li>
                    5.1 Alıcı, dijital ürünler için cayma hakkını kullanamaz.
                    Ancak diğer Ürünleri, Ürünün kendisine veya gösterdiği
                    adresteki kişi/kuruluşa teslim tarihinden itibaren 14
                    (ondört) gün içerisinde hiçbir hukuki ve cezai sorumluluk
                    üstlenmeksizin ve hiçbir gerekçe göstermeksizin malı
                    reddederek sözleşmeden cayma hakkını kullanabilir.
                  </li>
                  <li>
                    5.2 Cayma hakkı süresi, tüketicinin veya tüketici tarafından
                    belirlenen üçüncü kişinin malı teslim aldığı gün başlar.
                    Ancak tüketici, sözleşmenin kurulmasından malın teslimine
                    kadar olan süre içinde de cayma hakkını kullanabilir.
                  </li>
                  <li>
                    5.3 Cayma hakkının kullanılması için 14 (ondört) günlük süre
                    içinde Satıcı’ya iadeli taahhütlü posta, elektronik posta
                    veya faks ile yazılı bildirimde bulunulması şarttır. Cayma
                    hakkının kullanımına ilişkin ispat yükümlülüğü Alıcı’ya
                    aittir.
                  </li>
                  <li>
                    5.4 Alıcı, cayma hakkını kullandığına ilişkin bildirimi
                    yönelttiği tarihten itibaren 10 (on) gün içinde malı
                    Satıcı’ya geri göndermek zorundadır.
                  </li>
                  <li>
                    5.5 Satıcı, Alıcı’nın cayma hakkını kullandığına ilişkin
                    bildirimin kendisine ulaştığı tarihten itibaren en geç 14
                    (on dört) günlük süre içerisinde toplam bedeli ve Alıcı’yı
                    borç altına sokan belgeleri ALICI’ya iade etmekle
                    yükümlüdür.
                  </li>
                  <li>
                    5.6 Alıcı, Satıcı’nın malı gönderirken kullanmış olduğu
                    taşıyıcı ile ve Satıcı’nın ismini taşıyıcıya belirterek
                    göndermekle yükümlüdür. Satıcı’nın anlaşması olmayan
                    taşıyıcıların kullanımından doğacak taşıma masrafları
                    Alıcı’ya ait olacaktır.
                  </li>
                  <li>
                    5.7 Teslim alınmış olan malın değerinin azalması veya iadeyi
                    imkânsız kılan bir nedenin varlığı cayma hakkının
                    kullanılmasına engel değildir. Ancak değer azalması veya
                    iadenin imkânsızlaşması tüketicinin kusurundan
                    kaynaklanıyorsa Satıcı’ya malın değerini veya değerindeki
                    azalmayı tazmin etmesi gerekir. Malın mutat kullanımı
                    sebebiyle meydana gelen değişiklik ve bozulmalar değer
                    azalması sayılmaz.
                  </li>
                  <li>
                    5.8 Taraflar Mesafeli Satışa Dair Yönetmelik’in 7/4
                    gereğince aşağıdaki hallerde cayma hakkının
                    kullanılmayacağını kabul, beyan ve taahhüt ederler;
                  </li>
                  <ul
                    className="pl-5 mt-2 space-y-1 list-inside list-lower-alpha"
                  >
                    <li>
                      Cayma hakkı süresi sona ermeden önce, tüketicinin onayı
                      ile hizmetin ifasına başlanan hizmet sözleşmeleri.
                    </li>
                    <li>
                      Fiyatı borsa veya teşkilatlanmış diğer piyasalarda
                      belirlenen mallara ilişkin sözleşmeler.
                    </li>
                    <li>
                      Tüketici veya Alıcı tarafından ambalajı açılmış olan ses
                      veya görüntü kayıtlarına, yazılım programlarına ve
                      bilgisayar sarf malzemelerine ilişkin sözleşmeler.
                    </li>
                    <li>
                      Gazete, dergi gibi süreli yayınların teslimine ilişkin
                      sözleşmeler.
                    </li>
                    <li>
                      Bahis ve piyangoya ilişkin hizmetlerin ifasına ilişkin
                      sözleşmeler.
                    </li>
                    <li>
                      Elektronik ortamda anında ifa edilen hizmetler ve
                      tüketiciye veya Alıcı'ya anında teslim edilen gayri maddi
                      mallara ilişkin sözleşmeler.
                    </li>
                  </ul>
                </ul>
              </li>

              <p>
                Bu Sözleşme, taraflar arasında eşit şekilde kabul edilmiş ve
                imzalanmıştır.
              </p>

              <div>
                <h3>SATICI</h3>
                <p>
                  Adı Soyadı / Ünvanı: [SATICI ADI SOYADI / ÜNVANI] <br />
                  Tarih: [SATICI TARİHİ] <br />
                  İmza:
                </p>
              </div>

              <div>
                <h3>ALICI</h3>
                <p>
                  Adı Soyadı / Ünvanı: [ALICI ADI SOYADI / ÜNVANI] <br />
                  Tarih: [ALICI TARİHİ] <br />
                  İmza:
                </p>
              </div>
            </ol>
          </article>
        </div>
      </main>
    </>
  );
}

export default DistanceSellingAgreement;
