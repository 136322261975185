import axios from "axios";
import { Toast } from "flowbite-react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import Datepicker from "react-tailwindcss-datepicker";

function Contact() {
  const BEARER_TOKEN = process.env.REACT_APP_BEARER_TOKEN;

  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    event_type: "",
    event_date: "",
    event_guest_count: "",
    contact_type: "email",
  });

  const recaptchaRef = React.createRef();
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verify reCAPTCHA
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      toast.error("Lütfen doğrulamayı yapınız.");
      return;
    }

    try {
      const data = {
        ...formData,
        event_date: formData.event_date.startDate.toString(),
      };

      const response = await axios.post(
        "https://us-central1-davetlim-b23ef.cloudfunctions.net/app/contact",
        data,
        {
          headers: {
            Authorization: `Bearer ${BEARER_TOKEN}`,
          },
        }
      );
      toast.success("Başarıyla gönderildi", response);
      setFormData({
        full_name: "",
        email: "",
        phone_number: "",
        event_type: "",
        event_date: "",
        event_guest_count: "",
        contact_type: "",
      });
    } catch (error) {
      toast.error("Hata oluştu", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <Helmet>
        <title>İletişim | davetlim.org</title>
        <meta
          name="description"
          content="davetlim.org ile iletişime geçin. Sorularınızı, önerilerinizi veya taleplerinizi bize iletin. Ekibimiz size en kısa sürede dönüş yapacaktır."
        />
        <meta name="author" content="davetlim.org" />
        <meta name="robots" content="index, follow" />
        {/* Facebook Tags  */}
        <meta name="og:title" content="İletişim | davetlim.org" />
        <meta
          name="og:description"
          content="davetlim.org ile iletişime geçin. Sorularınızı, önerilerinizi veya taleplerinizi bize iletin. Ekibimiz size en kısa sürede dönüş yapacaktır."
        />
        <meta name="og:url" content="https://davetlim.org/iletisim#top" />
        <meta name="og:site_name" content="davetlim.org" />
        <meta name="og:type" content="website" />
        <meta
          name="og:image"
          content="https://davetlim.org/static/media/Logo.1668ecc39d9ff250c6c6.png"
        />
        {/* Twitter tags  */}
        <meta name="twitter:title" content="İletişim | davetlim.org" />
        <meta
          name="twitter:description"
          content="davetlim.org ile iletişime geçin. Sorularınızı, önerilerinizi veya taleplerinizi bize iletin. Ekibimiz size en kısa sürede dönüş yapacaktır."
        />
        <meta
          name="twitter:image"
          content="https://davetlim.org/static/media/Logo.1668ecc39d9ff250c6c6.png"
        />
        <meta name="twitter:image:alt" content="davetlim.org" />
        <meta name="twitter:site" content="https://davetlim.org/iletisim#top" />
        <meta name="twitter:creator" content="davetlim.org" />
      </Helmet>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="px-4 mx-auto max-w-screen-sm text-center lg:px-6 mb-8 lg:mb-16">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Bizimle İletişime Geçin
            </h2>
            <p className="font-light text-gray-600 dark:text-gray-400 sm:text-xl">
              Aşağıdaki formu doldurarak bize talebinizi iletebilirsiniz. En
              kısa sürede sizinle iletişime geçeceğiz.
            </p>
          </div>
          <div className="grid grid-cols-1 ">
            <div className="col-span-2 mb-8 lg:mb-0">
              <form
                action="#"
                className="grid grid-cols-1 gap-8 mx-auto max-w-screen-md sm:grid-cols-2"
                onSubmit={handleSubmit}
              >
                <div className="sm:col-span-2">
                  <label
                    htmlFor="full_name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Ad
                  </label>
                  <input
                    type="text"
                    id="full_name"
                    name="full_name"
                    value={formData.full_name}
                    onChange={handleChange}
                    className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    E-Posta
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone_number"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Telefon Numarası
                  </label>
                  <input
                    type="text"
                    id="phone_number"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    required
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="event_type"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Etkinlik Çeşidi (Düğün, Lansman, Toplantı vb.)
                  </label>
                  <input
                    type="text"
                    id="event_type"
                    name="event_type"
                    value={formData.event_type}
                    onChange={handleChange}
                    className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    required
                  />
                </div>
                <div className="sm:col-span-2">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Etkinlik Tarihi:
                  </label>

                  <Datepicker
                    i18n={"tr"}
                    inputClassName="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    asSingle={true}
                    primaryColor={"violet"}
                    useRange={false}
                    value={formData.event_date}
                    onChange={(value) => {
                      setFormData({ ...formData, event_date: value });
                    }}
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="event_guest_count"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Davetli Sayısı
                  </label>
                  <input
                    type="number"
                    id="event_guest_count"
                    name="event_guest_count"
                    value={formData.event_guest_count}
                    onChange={handleChange}
                    className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    required
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="contact_type"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Sizin ile nasıl iletişime geçelim?
                  </label>

                  <select
                    id="contact_type"
                    name="contact_type"
                    value={formData.contact_type}
                    onChange={handleChange}
                    className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  >
                    <option value="email">Email</option>
                    <option value="whatsapp">WhatsApp </option>
                    <option value="call">Arama</option>
                  </select>
                </div>

                <div className="sm:col-span-2">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  />
                </div>

                <button
                  type="submit"
                  className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Gönder
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
