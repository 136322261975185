import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/img/Logo.png";
import IyzicoLogo from "../assets/img/logo_band_colored@1X.png";
import { HashLink } from 'react-router-hash-link';

function Footer() {
  return (
    <>
      {" "}
      <footer className="p-4 bg-white md:p-8 lg:p-10 dark:bg-gray-800">
        <div className="mx-auto max-w-screen-xl text-center">
          <HashLink
            to="#"
            className="flex justify-center items-center text-2xl font-semibold text-gray-900 dark:text-white"
          >
            <img src={Logo} className="h-16" alt="davetlim" />
          </HashLink>

          <ul className="flex  flex-wrap justify-center items-center mb-6 text-gray-900 dark:text-white max-[560px]:flex-col  max-[560px]:gap-2  max-[560px]:w-full  ">
            <li>
              <HashLink
                to="/hakkimizda#top"
                className="mr-4 hover:underline md:mr-6  max-[560px]:mr-0  max-[560px]:text-lg"
              >
                Hakkımızda
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/gizlilik-politikasi#top"
                className="mr-4 hover:underline md:mr-6  max-[560px]:mr-0  max-[560px]:text-lg"
              >
                Gizlilik Politikası
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/kullanim-kosullari#top"
                className="mr-4 hover:underline md:mr-6  max-[560px]:mr-0  max-[560px]:text-lg"
              >
                Kullanım ve İade Koşulları
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/mesafeli-satis-sozlesmesi#top"
                className="mr-4 hover:underline md:mr-6  max-[560px]:mr-0  max-[560px]:text-lg"
              >
                Mesafeli Satiş Sözleşmesi
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/iletisim#top"
                className="mr-4 hover:underline md:mr-6  max-[560px]:mr-0  max-[560px]:text-lg"
              >
                İletişim
              </HashLink>
            </li>
          </ul>
          <div className="flex justify-center items-center text-2xl font-semibold text-gray-900 dark:text-white mb-6">
            <img src={IyzicoLogo} className="h-6" alt="iyzico" />
          </div>
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2023{" "}
            <Link to="/" className="hover:underline">
              davetlim.org™
            </Link>{" "}
            by InfiniCode
          </span>

        </div>
      </footer>
    </>
  );
}

export default Footer;
