import React from "react";
import { Helmet } from "react-helmet";

function TermsofUse() {
  return (
    <>
      <Helmet>
        <title>Kullanım ve İade Koşulları | davetlim.org</title>
        <meta name="description" content="davetlim.org'un Kullanım Koşulları sayfasında, web sitemizi ve hizmetlerimizi nasıl kullanabileceğiniz hakkında önemli bilgiler bulunmaktadır. Kullanıcı hakları, yükümlülükler ve diğer hukuki konular hakkında bilgi edinin." />
        <meta name="author" content="davetlim.org" />
        <meta name="robots" content="index, follow" />
        {/* Facebook Tags  */}
        <meta name="og:title" content="Kullanım ve İade Koşulları | davetlim.org" />
        <meta name="og:description" content="davetlim.org'un Kullanım Koşulları sayfasında, web sitemizi ve hizmetlerimizi nasıl kullanabileceğiniz hakkında önemli bilgiler bulunmaktadır. Kullanıcı hakları, yükümlülükler ve diğer hukuki konular hakkında bilgi edinin." />
        <meta name="og:url" content="https://davetlim.org/kullanim-kosullari#top" />
        <meta name="og:site_name" content="davetlim.org" />
        <meta name="og:type" content="website" />
        <meta name="og:image" content="https://davetlim.org/static/media/Logo.1668ecc39d9ff250c6c6.png" />
        {/* Twitter tags  */}
        <meta name="twitter:title" content="Kullanım ve İade Koşulları | davetlim.org" />
        <meta name="twitter:description" content="davetlim.org'un Kullanım Koşulları sayfasında, web sitemizi ve hizmetlerimizi nasıl kullanabileceğiniz hakkında önemli bilgiler bulunmaktadır. Kullanıcı hakları, yükümlülükler ve diğer hukuki konular hakkında bilgi edinin." />
        <meta name="twitter:image" content="https://davetlim.org/static/media/Logo.1668ecc39d9ff250c6c6.png" />
        <meta name="twitter:image:alt" content="davetlim.org" />
        <meta name="twitter:site" content="https://davetlim.org/kullanim-kosullari#top" />
        <meta name="twitter:creator" content="davetlim.org" />
      </Helmet>
      <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
        <div className="flex justify-between px-4 mx-auto max-w-screen-xl">
          <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
            <header className="mb-4 lg:mb-6 not-format">
              <h1 className="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">
                Kullanım ve İade Koşulları
              </h1>
            </header>
            <ol className="space-y-4 text-gray-500 list-inside dark:text-gray-400 list-upper-roman">
              <li>
                BAĞLAYICI SÖZLEŞME
                <ul className="pl-5 mt-2 space-y-1  list-inside list-upper-alpha">
                  <li>
                    Bu Hizmet Şartları ("Bu Sözleşme"), Etkinlik Sahibi ve
                    davetlim.org arasında bağlayıcı ve uygulanabilir bir
                    sözleşme oluşturur. Lütfen bu Sözleşmeyi dikkatlice okuyun.
                  </li>
                  <li>
                    Bu Sözleşme kapsamında, her bir kişi veya kuruluşun, bir
                    "Etkinlik" için kredi sattığı veya davetiyelerin
                    gönderildiği bir etkinlik düzenlediği, ürettiği veya kredi
                    satışı yapılan veya davetiyelerin gönderildiği bir hizmet
                    sunduğu (her biri bir "Etkinlik") ve bunların
                    yetkilendirilmiş acenteleri veya hizmet sağlayıcıları olduğu
                    kabul edilir. Her bir kredi satın alan kişiye "Satın Alan"
                    denir. Bir Etkinlik için davet alan her kişi "Misafir"
                    olarak adlandırılır.
                  </li>
                  <li>
                    davetlim.org, Etkinlik Sahipleri için Etkinlikler için web
                    tabanlı kredi satışı ve davet gönderme hizmetleri sunar.
                    davetlim.org, herhangi bir Etkinlik Sahibi ile ilişkili
                    değildir ve yalnızca Etkinlik Sahibi ile Satın Alan veya
                    Misafir arasında bir aracı olarak hareket eder.
                  </li>
                  <li>
                    Bu Sözleşme, yalnızca Etkinlik Sahibinin davetlim.org'u
                    kredi satışı veya davet gönderme hizmetleri için işe aldığı
                    belirli bir Etkinlik için geçerlidir ve Etkinlik Sahibi
                    tarafından düzenlenen veya üretilen diğer etkinlikler ayrı
                    bir sözleşme ile düzenlenir.
                  </li>
                </ul>
              </li>
              <li>
                ETKİNLİKLER
                <ul className="pl-5 mt-2 space-y-1 list-inside list-upper-alpha">
                  <li>
                    Reklam. Bu Sözleşme, davetlim.org'un Etkinlik Sahibi adına
                    herhangi bir Etkinlikle ilgili olarak herhangi bir
                    sponsorluk, destek, reklam, tanıtım veya başka bir eylemde
                    bulunma yükümlülüğü yaratmaz.
                  </li>
                  <li>
                    Sorumluluk Sınırlaması. davetlim.org, kredi satışı veya
                    davet gönderme hizmetleri sağladığı herhangi bir Etkinlikle
                    ilgili olarak bu Sözleşmede açıkça belirtilenler dışında
                    herhangi bir sorumluluğa sahip değildir. Bu Sözleşme
                    uyarınca davetlim.org'un toplam sorumluluğu, Satın
                    Alanlardan ve Etkinlik Sahiplerinden tahsil edilen tüm
                    tutarların toplamını aşmaz.
                  </li>
                </ul>
              </li>
              <li>
                ETKİNLİK SAHİBİNİN TEMSİL VE GARANTİLERİ
                <ul className="pl-5 mt-2 space-y-1  list-inside list-upper-alpha">
                  <li>
                    Temsil ve Garantiler. Etkinlik Sahibi, davetlim.org'a
                    aşağıdaki temsil ve garantileri verir: Etkinlik için
                    davetiyeler göndermeye yetkilidir. Etkinlik için göndermeyi
                    düşündüğü davetiyelerin sayısı, mekanın ilgili
                    düzenlemelerine ve kısıtlamalara, dahil ancak bunlarla
                    sınırlı olmamak üzere yerel, devlet ve federal yasa ve
                    düzenlemelere ve Etkinlik mekanı tarafından uygulanan
                    herhangi bir kısıtlamaya uyar. Etkinlik ile ilgili olarak
                    her türlü devlet, yerel, federal ve diğer vergilerden
                    sorumludur. davetlim.org web sitesine Etkinlik Sahibi
                    tarafından yüklenen veya davetlim.org'a herhangi bir amaçla
                    sağlanan tüm materyalin, Etkinlik Sahibi tarafından
                    sahiplenildiğini veya kullanma hakkına sahip olduğunu ve
                    böyle bir materyal veya bilginin herhangi bir üçüncü tarafın
                    patent, telif hakkı veya diğer fikri mülkiyet hakkına
                    tecavüz etmediğini kabul eder.
                  </li>
                </ul>
              </li>
              <li>
                KREDİ FİYATLANDIRMA; ÜCRETLER
                <ul className="pl-5 mt-2 space-y-1 list-inside list-upper-alpha">
                  <li>
                    Kredi Fiyatları. Etkinlik Sahibi, Etkinlik için kredi
                    fiyatını veya fiyatlarını belirleme sorumluluğuna sahiptir.
                  </li>
                  <li>
                    Hizmet Ücretleri. Etkinlik Sahibi, https://www.davetlim.org/
                    adresinde bulunan Fiyatlandırma Tarifesi'nde belirtilen
                    hizmet ücretlerini davetlim.org'a öder. Etkinlik Sahibi,
                    Davetiyeleri ve katılım yönetimi hizmetlerini sunması için
                    davetlim.org'u tutarsa, Etkinlik Sahibi hizmet ücretlerini
                    peşin olarak öder.
                  </li>
                </ul>
              </li>
              <li>
                KAPASİTE; DAVETİYELER; İADELER
                <ul className="pl-5 mt-2 space-y-1 list-upper-alpha list-inside">
                  <li>
                    Kapasite. Etkinlik Sahibi, her bir Etkinlik için satın
                    alınabilir kredi sayısını veya davetiyelerin gönderileceği
                    kişi sayısını belirler. Etkinlik Sahibi bu belirlemekten
                    tamamen sorumludur ve davetlim.org, herhangi bir mekanın
                    yasal kapasitesinin üzerinde satılan krediler veya fazla
                    davetiyeler veya herhangi bir kişiye, herhangi bir mekana
                    veya kredilerin yasal kapasitesinin üzerinde satılmasına
                    ilişkin herhangi bir sorumluluğa sahip değildir.
                  </li>
                  <li>
                    İadeler. <br />
                    TÜM SATIŞLAR NİHAİDİR. Kredilerin fazla satılması veya
                    Etkinliğin iptali nedeniyle veya Etkinlik ertelendiğinde
                    veya yeni bir mekana taşındığında kullanılamaz hale gelirse,
                    Etkinlik Sahibi'ne herhangi bir iade veya kredi değişimi
                    için başvuramayacağını kabul eder ve davetlim.org, bu
                    konuyla ilgili olarak herhangi bir sorumluluğa veya
                    yükümlülüğe sahip olmaz. davetlim.org, davetiye ve katılım
                    yönetimi hizmetleri satın alan herhangi bir Etkinlik
                    Sahibine herhangi bir nedenle iade yapma yükümlülüğünde
                    değildir.
                  </li>
                </ul>
              </li>
              <li>
                HİZMETLERİN YENİDEN SATILMASI
                <ul className="pl-5 mt-2 space-y-1 list-upper-alpha list-inside">
                  <li>
                    Herhangi bir kullanıcının davetlim.org hizmetlerini önceden
                    yazılı bir anlaşma olmaksızın yeniden satması mümkün
                    değildir.
                  </li>
                </ul>
              </li>
              <li>
                BİR BİREYİN DAVETLİ BAĞLANTISINI PAYLAŞMA
                <ul className="pl-5 mt-2 space-y-1 list-upper-alpha list-inside">
                  <li>
                    Bir ev sahibinin posta listesindeki tüm davetlilerin
                    bağlantıları kişiselleştirilmiştir ve yalnızca bir kişi
                    içindir. Bir kişinin kişiselleştirilmiş bağlantısının aşırı
                    derecede paylaşıldığını düşündüğümüz durumlarda, bu belirli
                    bağlantının devre dışı bırakılmasına neden olabilir.
                    Kişiselleştirilmiş bağlantının aşırı derecede paylaşıldığı
                    durumlarda bu durumu düzeltmek için 24 saat süreniz
                    olacaktır ve davetlim.org, bu dönüşümü nasıl yapacağınıza
                    dair talimatlar içeren bir e-posta gönderecektir.
                  </li>
                </ul>
              </li>
              <li>
                İZİN GEREKEN POSTA LİSTELERİ; SPAM/KÖTÜYE KULLANIM ŞİKAYETLERİ
                <ul className="pl-5 mt-2 space-y-1 list-upper-alpha list-inside">
                  <li>
                    Posta Listeleri. Satış/pazarlama amacı taşıyan etkinlikleri
                    gönderen Etkinlik Sahipleri, sadece satın alıcının
                    satış/pazarlama iletişimi almaya izin verdiği e-posta
                    adreslerine gönderme yapabilir. Satın alıcının iletişim
                    almayı kabul ettiği şekilde satın alınmamış, kiralanmamış
                    veya edinilmemiş bir kişilere iletişim göndermek izin
                    verilmemektedir. Bu kılavuza uymamak, hesabınızın geri ödeme
                    olmadan askıya alınmasına neden olabilir.
                  </li>
                  <li>
                    Spam Şikayetleri. davetlim.org, sistemimizden gönderilen
                    istenmeyen/spam mesajları tolere etmez. Hesabınızdan
                    gönderilen kartlar/mesajlar, e-posta sağlayıcıları (AOL,
                    Yahoo, Hotmail vb.) tarafından bildirilen yüksek miktarda
                    spam şikayeti üretirse, davetlim.org, hesabınızı geri ödeme
                    olmadan askıya alma hakkını saklı tutar.
                  </li>
                  <li>
                    Nefret Dolu İçerik. davetlim.org, diğerlerine zarar vermeyi
                    teşvik eden veya kışkırtan hesapları kabul etmez. Bu,
                    hesabınızın, diğerlerine zarar verebileceğimize inanılan
                    kartlar veya mesajlar oluşturduğunuz veya gönderdiğiniz
                    takdirde, hesabınızın geri ödeme olmadan askıya alınacağı
                    anlamına gelir.
                  </li>
                </ul>
              </li>
              <li>
                ÜCRETSİZ DENEYİM HESABI
                <ul className="pl-5 mt-2 space-y-1 list-upper-alpha list-inside">
                  <li>
                    . Her kullanıcıya yalnızca bir ücretsiz deneyim hesabına
                    izin verilir. Benzer bir posta göndermeye yönelik çoklu
                    ücretsiz deneyim hesapları oluşturmak, hesabınızın gözden
                    geçirilmesi için işaretlenir. Bu şekilde davranmaya devam
                    etmek, hesabınızın geri ödeme olmadan askıya alınmasına
                    neden olacaktır.
                  </li>
                </ul>
              </li>
              <li>
                HESAP UYUŞMAZLIĞI
                <ul className="pl-5 mt-2 space-y-1 list-upper-alpha list-inside">
                  <li>
                    Kayıtlar. Eğer davetlim.org bir Etkinlik için kredi satma
                    hizmeti sunmak üzere Etkinlik Sahibi tarafından işe
                    alınırsa, Etkinlik Sahibi'nin davetlim.org hesabı aktif ve
                    düzgün durumda olduğu süre boyunca, davetlim.org, Etkinlik
                    Sahibi adına yapılan tüm kredi satışlarının tam ve doğru
                    kayıtlarını tutar, her bir kredi için ödenen kredi fiyatını
                    ve her bir kredi için ödenen hizmet ücretini içerir.
                  </li>
                  <li>
                    Ödeme. davetlim.org, Etkinlik için kredi satma hizmeti
                    sunmak üzere Etkinlik Sahibi tarafından tutulursa, ödemeler
                    Stripe aracılığıyla işlenecektir. Stripe ile kurulum yapılır
                    yapılmaz, ödemeler banka hesabınıza 2 günlük bir süre
                    zarfında ulaşacaktır. Ayrıca, haftalık veya aylık olarak
                    transferleri almayı tercih edebilirsiniz. En son transfer
                    protokolleri hakkında ayrıntılar için Stripe.com'u kontrol
                    ediniz.
                  </li>
                </ul>
              </li>
              <li>
                TAZMİNAT
                <ul className="pl-5 mt-2 space-y-1 list-upper-alpha list-inside">
                  <li>
                    Tazminat. Etkinlik Sahibi, davetlim.org'u, üyelerini,
                    yöneticilerini, memurlarını, çalışanlarını ve acentelerini,
                    yüklenici veya müşterilerini de içeren ve yüksek bir
                    ihtimalle zarar vermekten, tehdit etmekten, taciz etmekten
                    veya diğerlerine karşı ayrımcılık yapmaktan veya başka bir
                    yolla zarar vermekten, tehdit etmekten, taciz etmekten veya
                    diğerlerine karşı ayrımcılık yapmaktan sorumlu tutar ve
                    tazmin eder ve davetlim.org'u, bu Anlaşma'nın şartlarına
                    uymamasından veya herhangi bir yasal gereklilikten, kuraldan
                    veya düzenlemesinden, herhangi bir mekan tarafından
                    yayımlanan herhangi bir kural, düzenleme veya politika dahil
                    olmak üzere, burada yer alan beyanlar ve teminatlara dahil
                    edilenler de dahil olmak üzere herhangi bir üçüncü tarafın
                    patent, telif hakkı veya diğer fikri mülkiyet hakkına
                    tecavüz etmemesi nedeniyle doğrudan veya dolaylı olarak
                    neden olabilecek, neden olabilecek veya başka bir şekilde
                    neden olabileceği veya suçlanabileceği, ihmal veya başka bir
                    şekilde, Etkinlik Sahibi veya Etkinlik Sahibi'nin bu
                    Anlaşma'nın şartlarına uymamasından kaynaklanan tüm
                    talepler, davalardan, zararlardan, kayıplardan,
                    sorumluluklardan veya masraflardan kaynaklanan herhangi bir
                    türden, yasal ücretler dâhil olmak üzere, ve yasal,
                    düzenleyici veya diğer vergiler dahil olmak üzere, Etkinlik
                    ve kredi satışı ile ilgili olarak eyalet, yerel, federal ve
                    diğer vergiler dahil olmak üzere ve davetlim.org'un web
                    sitesine Etkinlik Sahibi tarafından gönderilen veya herhangi
                    bir amaç için davetlim.org'a verilen her türlü malzemenin
                    sahibi veya başka şekilde kullanma yetkisine sahiptir ve
                    böyle bir malzemenin veya bilginin kullanımı, üçüncü bir
                    tarafın herhangi bir patent, telif hakkı veya diğer fikri
                    mülkiyet hakkına tecavüz etmez. Bu tazminat yükümlülüğünün
                    taraflar, yukarıda belirtilen konuyla ilgili herhangi bir
                    iddia, dava veya nedenin geçerli ve nihai olarak ilgili
                    zamana kadar uygulanabilir olduğunu kabul ederler.
                  </li>
                </ul>
              </li>
            </ol>
          </article>
        </div>
      </main>
    </>
  );
}

export default TermsofUse;
